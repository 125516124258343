import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [{
  path: '/',
  name: 'Home',
  component: () => import('../views/Home.vue')
}]

const router = new VueRouter({
  // mode: 'history', // history模式，默认hash
  // base: '/navigation', // 基础路径
  routes
})

export default router