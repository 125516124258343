// 引入axios
import axios from "axios"
// 引入config文件
import {
    Method
} from "./config";

// 通过配置对象返回一个axios实例对象
const instance = axios.create({
    // 路由地址（前面相同）
    baseURL: "https://www.aiforscience.cn/newApi",
    // 请求超时时间
    timeout: 10000
})

// 请求拦截
instance.interceptors.request.use(function (config) {
    // 在发送请求之前
    config.headers['Authorization'] = localStorage.getItem('token')
    return config;
}, function (error) {
    // 请求错误
    return Promise.reject(error);
});

// 响应拦截器
instance.interceptors.response.use(response => {
    // return response;
    if (response.code == 401) {
        console.log('token失效')
        localStorage.removeItem('token')
    }
    // console.log(response)
    return response
}, err => {
    console.log(err)
    // err=JSON.stringify(err)
    // console.log(err.length)
    if(err.toString().includes('401')){
        console.log(2);
        localStorage.removeItem('token')
    }
    // 响应错误
    // switch (error.code) {
    //     case 401:
    //         console.log('token失效');
    //         localStorage.removeItem('token')
    //         break;
    //     case 500:
    //         console.log('登录已经过期');
    //         localStorage.removeItem('token')
    //         break;
    // }
});

export function request(method, url, params) {
    switch (method) {
        case Method.GET:
            return GET(url, params);
        case Method.POST:
            return POST(url, params)
    }
}

function GET(url, params) {
    return instance.get(url, params)
}

function POST(url, params) {
    return instance.post(url, params)
}
