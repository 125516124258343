import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// 引入element-ui的组件库
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

// 引入axios
import axios from 'axios'
// 将axios挂载vue的原型上
Vue.prototype.$axios = axios

Vue.config.productionTip = false
// 引入重置样式
import './assets/css/reset.css'
// 引入阿里巴巴矢量图标库
import './assets/iconfont/iconfont.css'
// 引入公共样式
import './assets/css/common.css'

// 网络模块
import netClient from "./network/index"
// 网络模块
Vue.prototype.$api = netClient;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')