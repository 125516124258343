// 引入core文件
import {
  request
} from "./core"
// 引入config文件
import {
  Method,
  PATH
} from "./config"

const netClient = {
  // 首页路径
  loginImg(params) {
    return request(Method.GET, PATH.loginImg, params)
  },
  // 扫码登录
  obtainSeneKe(params) {
    return request(Method.POST, PATH.obtainSeneKe+`?sceneKey=${params}`, params)
  },
  // 权限
  jurisdiction(params){
    return request(Method.GET,PATH.jurisdiction,params)
  }
}

// 抛出netClient
export default netClient;