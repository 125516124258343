// 定义请求方式
export const Method = {
    GET: "get",
    POST: "post"
}

export const PATH = {
    // 登录二维码
    loginImg: "/wechat/publicNumber/getWxLoginUrl",
    // 登录
    obtainSeneKe: '/auth/loginToken',
    // 权限
    jurisdiction: '/system/user/getPermissionList'
}