<template>
  <div id="app">
    <header>
      <div class="conent">
        <img src="./assets/images/logo.png" alt="" />
        <p>用数据，用AI，搜AIforScience</p>
      </div>
    </header>
    <router-view />
    <div class="line"></div>
    <footer>
      <div class="wrap">
        <div @click="gw">「AI for Science」官网</div>
        <div @click="fw">服务条款</div>
        <div @click="ys">隐私政策</div>
        <div @click="lx">联系我们</div>
        <div>
          Copyright ©2019-2021 北京砥脊科技有限公司 All Rights Reserved.
          &emsp;&emsp;<span @click="jump">京ICP备20008055号-1</span>
        </div>
      </div>
    </footer>
  </div>
</template>
<script>
export default {
  methods: {
    jump(){
      window.open('http://beian.miit.gov.cn/','_blank')
    },
    // 联系我们
    lx() {
      window.open("https://www.aiforscience.cn/home/aboutUs.html", "_blank");
    },
    // 隐私政策
    ys() {
      window.open("https://www.aiforscience.cn/cops/security", "_blank");
    },
    // 服务
    fw() {
      window.open("https://www.aiforscience.cn/cops/clause", "_black");
    },
    // 官网
    gw() {
      window.open("https://www.aiforscience.cn/home/", "_blank");
    },
  },
};
</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 900px;
  //   margin: 0 auto;
  //   padding-top: 20px;
  //   box-sizing: border-box;
  // position: relative;
}
header {
  width: 100%;
  height: 56px;
  background: #2977ff;
  .conent {
    width: 1200px;
    height: 56px;
    display: flex;
    align-items: center;
    margin: 0 auto;
    img {
      width: 127px;
      height: 39px;
    }
    p {
      // width: 176px;
      height: 24px;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      line-height: 24px;
      margin-left: 19px;
    }
  }
}
.line {
  width: 1440px;
  height: 1px;
  background: #f0f0f0;
  margin-top: 37px;
}
footer {
  width: 1200px;
  margin: 0 auto !important;
  user-select: text;
  .wrap {
    display: flex;
    margin-top: 24px;
    margin-bottom: 16px;
    div {
      &:first-child {
        width: 127x;
        height: 20px;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #8c8c8c;
        line-height: 20px;
        margin-left: 149px;
      }
      &:nth-child(2) {
        width: 48px;
        height: 20px;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #8c8c8c;
        line-height: 20px;
        margin-left: 32px;
      }
      &:nth-child(3) {
        width: 48px;
        height: 20px;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #8c8c8c;
        line-height: 20px;
        margin-left: 32px;
      }
      &:nth-child(4) {
        width: 48px;
        height: 20px;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #8c8c8c;
        line-height: 20px;
        margin-left: 32px;
      }
      &:nth-child(5) {
        width: 510px;
        height: 20px;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #8c8c8c;
        line-height: 20px;
        margin-left: 24px;
      }
    }
  }
}
</style>
